<template>
  <el-container class="SubLayout">
    <!-- 侧边导航栏 -->
    <SubSider
      class="SubSider"
      :defaultActive="defaultActive"
      :navData="navData"
      @select="handleGo"
    ></SubSider>
    <!-- 主页面 -->
    <SubContent class="SubContent"></SubContent>
  </el-container>
</template>

<script>

import SubSider from './SubSider.vue';
import SubContent from './SubContent.vue';

export default {
  name: 'SubLayout',
  components: {
    SubSider,
    SubContent,
  },
  props: {
    navData: {
      type: Array,
      default() {
        return [];
      }
    },
    parentName: {
      type: String,
      default: '',
      required: true,
    }
  },
  data() {
    return {
      // 默认路由
      defaultSub: null,
    };
  },
  computed: {
    defaultActive() {
      const { navData, defaultSub } = this;
      return defaultSub ? defaultSub.name : navData[0].name;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(val, oldVal) {
        const { navData, parentName } = this;
        const currentMenu = navData.find(obj => obj.name === val.name);
        if (currentMenu) {
          if (currentMenu.meta.redirect && currentMenu.children && currentMenu.children.length) {
            this.defaultSub = currentMenu.children[0];
          }else {
            this.defaultSub = currentMenu;
          }
        } else if (parentName === val.name) {
          if (this.defaultSub) {
            this.$router.push(this.defaultSub.path);
          } else {
            if (navData[0].meta.redirect && navData[0].children && navData[0].children.length) {
               this.$router.push(navData[0].children[0].path);
            }else {
               this.$router.push(navData[0].path);
            }
          }
        }
      }
    }
  },
  methods: {
    handleGo(menu) {
      this.$router.push(menu.path);
      this.$emit('select', menu);
    },
  }
}
</script>

<style lang="scss" scoped>
.SubLayout {
  position: relative;
  width: 100%;
  height: 100%;
}
.SubSider {
  position: relative;
  // 重置el-aside宽度
  width: 240px !important;
  height: 100%;
}
.SubContent {
  position: relative;
  width: calc(100% - 240px);
  height: 100%;
}
</style>