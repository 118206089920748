<template>
  <div class="NavMenu">
    <el-menu
      :default-active="defaultActive"
      :default-openeds="defaultOpendeds"
      @select="onSelect"
    >
      <NavSubMenu v-for="menu in navMenus" :key="menu.name" :menu="menu"></NavSubMenu>
    </el-menu>
  </div>
</template>

<script>
import NavSubMenu from './NavSubMenu.vue';

export default {
  name: 'NavMenu',
  components: {
    NavSubMenu,
  },
  props: {
    navMenus: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultActive: {
      type: String,
      default: '',
    },
    defaultOpendeds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  mounted() {

  },
  methods: {
    onSelect(index, indexPath) {
      const selectMenu = this.findMenus(indexPath);
      this.$emit('select', selectMenu);
    },
    findMenus(indexPath) {
      // 目标`Menu`
      const names = [...indexPath];
      let menus = [...this.navMenus];
      let findMenu;

      while (names.length > 0 && menus.length > 0) {
        const name = names.splice(0, 1)[0];
        findMenu = menus.find(menu => menu.name === name);

        menus = [];
        if (findMenu
          && findMenu.children
          && findMenu.children.length > 0) {
          menus = [...findMenu.children];
        }
      }

      return findMenu;

    }
  }
}
</script>

<style lang="scss" scoped>
.NavMenu {
  position: relative;
  width: 100%;
  height: 100%;
}
// 重置`el-menu`的样式
::v-deep .el-menu {
  border-right: unset;
  background-color: #2f3a48;
  // 重置`el-menu-item`背景色
  .el-menu-item,
  .el-submenu__title {
    color: #fff;
    &:hover {
      background-color: #3e4b5d;
    }
  }
  // 当前`el-menu-item1
  .is-active:not(.is-opened) {
    color: #fff;
    background-color: #3e4b5d;
  }
}
</style>