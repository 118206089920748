<template>
  <component :is="renderTag" :index="menu.name">
    <!-- 渲染 `menu-item` -->
    <template #title>
      <div class="menuLabel">
        <!-- <img class="menuImg" v-if="menu.meta.icon" :src="menu.meta.icon" /> -->
        <Iconfont :name="menu.meta.icon" :mRight="7" :mTop="0" :size="16"></Iconfont>
        {{menu.meta.label}}
      </div>
    </template>

    <!-- 渲染 `sub-menu-item` -->
    <template v-if="isSubMenu">
      <NavSubMenu
        v-for="submenu in subMenu"
        :key="submenu.name"
        :menu="submenu"
      ></NavSubMenu>
    </template>
  </component>
</template>

<script>
import { hasPerms } from "@/utils/auth";
export default {
  name: 'NavSubMenu',
  props: {
    menu: {
      /** RouteMenu */
      type: Object,
      default: null
    },
  },
  computed: {
    isSubMenu() {
      return this.menu && this.menu.children && this.menu.children.length;
    },
    renderTag() {
      if (this.isSubMenu) return 'el-submenu';
      return 'el-menu-item';
    },
    subMenu() {
      if (this.isSubMenu) {
        return this.menu.children.filter(sub => (!sub.meta.permId || hasPerms(sub.meta.permId)) && !sub.meta.hideMenu);
      } else {
        return [];
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.menuLabel {
  display: flex;
  align-items: center;
}
.menuImg {
  margin-right: 10px;
  width: 14px;
  height: 14px;
}
</style>