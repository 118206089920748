<template>
  <el-main class="SubContent">
    <PageView :include="include" :max="100"></PageView>
  </el-main>
</template>

<script>

import { mapState } from 'vuex';
import PageView from '@/layout/PageView.vue';
import aeepAlive from "@/router/aeepAlive";

export default {
  name: 'SubContent',
  components: {
    PageView,
  },
  computed: {
    // ...mapState(['cachePages']),
    include() {
      return aeepAlive;
    }
  },
}
</script>

<style lang="scss" scoped>
.SubContent {
  padding: 10px 10px 0;
  background-color: #eceeef;
}
</style>

