<template>
  <SubLayout parentName="routerCalculateManagement"
    :navData="navData"></SubLayout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SubLayout from '@/layout/SubLayout';
import * as routerCalculateManagement from '@/router/modules/calculateManagement';

export default {
  // `name`与路由保持一致
  name: 'routerCalculateManagement',
  components: {
    SubLayout,
  },
  data() {
    return {};
  },
  computed: {
    // ...mapState(['cachePages']),
    navData() {
      return Object.values(routerCalculateManagement).filter(val => !val.meta.hideMenu);
    }
  },
  methods: {
    ...mapMutations(['setCachePages']),
  },
  beforeDestroy() {
    // 从store `cachePages` 清除所有子页面
    // let { cachePages, navData } = this;
    // cachePages = cachePages
    //   .filter(menu => !navData.some(nav => nav.name === menu.name));
    // this.setCachePages(cachePages);
  },
}
</script>