<template>
  <el-aside class="SubSider">
    <NavMenu
      :defaultActive="defaultActive"
      :navMenus="navList"
      @select="$emit('select',$event)"
    ></NavMenu>
  </el-aside>
</template>

<script>

import NavMenu from '@/layout/NavMenu';
import { hasPerms } from '@/utils/auth';

export default {
  name: 'SubSider',
  components: {
    NavMenu,
  },
  props: {
    navData: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultActive: {
      type: String,
      default: '',
    },
  },
  computed: {
    navList() {
      return this.navData.filter(item => hasPerms(item.meta.permId));
    }
  },
  data() {
    return {
      // navData,
    };
  }
}
</script>

<style lang="scss" scoped>
.SubSider {
  background-color: #2f3a48;
  display: flex;
}
</style>