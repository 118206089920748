<template>
  <keep-alive :include="include" :max="max">
    <RouterView>
      <template #default="{ Component, route }">
        <component :is="Component" :key="route.path" />
      </template>
    </RouterView>
  </keep-alive>
</template>

<script>
/**
 * 路由渲染页面
 */

export default {
  name: 'PageView',
  props: {
    // 需要缓存的页面, 页面组件`name`和路由`name`一直
    include: {
      type: Array,
      default() {
        return [];
      }
    },
    // 最大缓存量
    max: {
      type: Number,
      default: 0,
    }
  }
}
</script>



